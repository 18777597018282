<template>
    <div>

        <v-container>
            <material-card class="mx-4"
                           :title="get_title"
                           text="Data for wellstar sales staff">

                <template v-if="!!order" :slot="'header_actions'">
                    <v-btn small fab class="ml-2"
                           @click="load_order">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn small fab class="ml-2"
                           @click="chat_opened = !chat_opened">
                        <v-icon>mdi-message</v-icon>
                    </v-btn>
                    <v-btn small fab
                           class="outline_btn stage_button ml-2"
                           :class="'stage_btn-' + get_stage">
                        {{ get_stage }}
                    </v-btn>
                </template>

                <v-container>
                    <v-row v-if="!order && !!is_loading">
                        <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                    </v-row>

                    <template v-if="!!order && !is_loading">

                        <h3 class="mt-1">Items</h3>

                        <v-row>
                            <v-simple-table dense class="invoice_items_table" style="max-width: 100%;">
                                <thead>
                                <tr>
                                    <th class="text-left">Item Number</th>
                                    <th class="text-left">Img</th>
                                    <th class="text-left">Name</th>
                                    <th class="text-center" width="70px">C's per carton</th>
                                    <th class="text-center" colspan="3">Item Size</th>
                                    <th class="text-center" colspan="3">Carton Size</th>
                                    <th class="text-center">Item CBM</th>
                                    <th class="text-center">Carton CBM</th>
                                    <th class="text-center">Ttl Item CBM</th>
                                    <th class="text-center">Ttl CTN CBM</th>
                                    <th class="text-left">QTY</th>
                                    <th class="text-left">Total Master CTN</th>
                                    <th class="text-center">FOB</th>
                                    <th class="text-center">Total</th>
                                    <th class="text-center">Remarks</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(line, key) of order.orderlines" :key="key">
                                    <td> {{line.product.item_number}} </td>
                                    <td>
                                        <template v-if="line.product.album">
                                            <v-img v-if="line.product.album.files.length"
                                                   class="white--text"
                                                   width="100px"
                                                   :alt="line.product.item_number"
                                                   :src="line.product.album.files[0].url">
                                            </v-img>
                                        </template>
                                    </td>
                                    <td>{{ line.product.name }}</td>
                                    <td class="text-center">
                                        <v-text-field
                                                v-model="line.pieces_per_master_carton"
                                                type="number"
                                                label=""
                                        ></v-text-field>
                                    </td>
                                    <td class="text-center pr-1">
                                        <v-text-field
                                                readonly
                                                hint="N/A"
                                                style="max-width: 50px"
                                                v-model="line.product.item_size_width"
                                                type="number"
                                                label="Width"
                                        ></v-text-field>
                                    </td>
                                    <td class="text-center px-1">
                                        <v-text-field
                                                readonly hint="N/A"
                                                style="max-width: 50px"
                                                v-model="line.product.item_size_height"
                                                type="number"
                                                label="Height"
                                        ></v-text-field>
                                    </td>
                                    <td class="text-center pl-1">
                                        <v-text-field
                                                readonly hint="N/A"
                                                style="max-width: 50px"
                                                v-model="line.product.item_size_depth"
                                                type="number"
                                                label="Depth"
                                        ></v-text-field>
                                    </td>

                                    <td class="text-center pr-1">
                                        <v-text-field
                                                readonly hint="N/A"
                                                style="max-width: 50px"
                                                v-model="line.carton_measures_width"
                                                type="number"
                                                label="Width"
                                        ></v-text-field>
                                    </td>
                                    <td class="text-center px-1">
                                        <v-text-field
                                                readonly hint="N/A"
                                                style="max-width: 50px"
                                                v-model="line.carton_measures_height"
                                                type="number"
                                                label="Height"
                                        ></v-text-field>
                                    </td>
                                    <td class="text-center pl-1">
                                        <v-text-field
                                                readonly hint="N/A"
                                                style="max-width: 50px"
                                                v-model="line.carton_measures_depth"
                                                type="number"
                                                label="Depth"
                                        ></v-text-field>
                                    </td>
                                    <td class="text-center">{{ calc_item_cbm(line) }}</td>
                                    <td class="text-center">{{ calc_carton_cbm(line) }}</td>
                                    <td class="text-center">{{ calc_item_ttl(line) }}</td>
                                    <td class="text-center">{{ calc_carton_ttl(line) }}</td>
                                    <td class="text-center">
                                        <v-text-field
                                                style="max-width: 50px"
                                                label=""
                                                v-model="line.qty"
                                                type="number"
                                        ></v-text-field>
                                    </td>
                                    <td class="text-center">{{ calc_total_master_ctn(line) }}</td>
                                    <td class="text-center">{{ line.organisation_fob_price | currency }}</td>
                                    <td class="text-center">{{ line.organisation_fob_price * line.qty | currency }}</td>
                                    <td>
                                        <v-textarea
                                                label=""
                                                v-model="line.remarks"
                                                class="">
                                        </v-textarea>
                                    </td>
                                </tr>
                                </tbody>

                            </v-simple-table>
                        </v-row>

                        <h3 class="mt-8">Additional Information</h3>

                        <v-row>
                            <v-simple-table dense>
                                <thead>
                                    <tr>
                                        <th style="width:150px;" class="text-left">Total CTN</th>
                                        <th style="width:130px;"> {{ total_ctn }} </th>
                                    </tr>
                                    <tr>
                                        <th class="text-left"> Total Item CBM </th>
                                        <th> {{ total_item_cbm.toFixed(2) }} </th>
                                    </tr>
                                    <tr>
                                        <th class="text-left"> Total Carton CBM </th>
                                        <th>{{ total_carton_cbm.toFixed(2) }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-left"> Total </th>
                                        <th> {{ client_total | currency }} </th>
                                    </tr>
                                </thead>
                            </v-simple-table>
                        </v-row>

                        <!--            <v-row>-->
                        <!--                <v-textarea-->
                        <!--                        label="Full Order Remarks"-->
                        <!--                        v-model="order.master_remarks"-->
                        <!--                        class=""></v-textarea>-->

                        <!--            </v-row>-->

                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        v-model="order.pi_number"
                                        :counter="100"
                                        label="PI Number"
                                        :disabled="true"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        v-model="order.po_number"
                                        :counter="100"
                                        label="PO Number"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-textarea
                                    name="input-7-1"
                                    label="General remarks"
                                    v-model="order.remarks"
                            ></v-textarea>
                        </v-row>

                        <v-divider></v-divider>

                        <v-btn block color="primary"
                               @click="saveOrder()">
                            Save PI
                        </v-btn>

                    </template>

                </v-container>

            </material-card>

            <div class="order-chat-wrapper" v-if="chat_opened">
                <w-order-chat :selected_invoice="order" @close_chat="chat_opened = false"></w-order-chat>
            </div>
        </v-container>

    </div>

</template>

<script>

    import { calc_item_cbm, calc_carton_cbm,
             calc_item_ttl, calc_carton_ttl,
             calc_total_master_ctn}
        from "@/plugins/wellstar_functions"

    export default {
        data() {
            return {
                is_loading: true,
                chat_opened: false,

                order_old: {},
                order: {},

                currencies: [ '$', '¥', '€', '£' ],

                factories: [],
                factory: {},
                email_dialog: false,

            }
        },
        computed: {
            allowed_access_to_final: function (){
                return this.$store.getters.user_groups.filter(value => [1, 2, 15].includes(value));
            },
            get_title: function(){
                return (this.order)? ((this.order.stage < 5)? 'Quote: ':'Product Invoice: ') + this.order.pi_number : "Product Invoide: Edition";
            },
            get_stage: function(){
                return (this.order)? this.order.stage : 1;
            },
            total_ctn: function(){
                return (!!this.order)? this.order.orderlines.reduce((a, line) => a + (line['pieces_per_master_carton'] || 0), 0) : 0;
            },
            total_item_cbm: function(){
                return (!!this.order)? this.order.orderlines.reduce((a, line) => a + ( this.calc_item_ttl(line) || 0), 0) : 0;
            },
            total_carton_cbm: function(){
                return (!!this.order)? this.order.orderlines.reduce((a, line) => a + ( this.calc_carton_ttl(line) || 0), 0) : 0;
            },
            client_total: function(){
                return (!!this.order)? this.order.orderlines.reduce((a, line) => a + ( parseInt(line.qty) * parseFloat(line.organisation_fob_price) || 0), 0) : 0;
            },
        },

        created() {
            this.invoice_id = this.$route.params.invoice_id;
            this.load_order();
        },

        methods: {
            calc_item_cbm(line) {
                return calc_item_cbm(line);
            },
            calc_carton_cbm(line) {
                return calc_carton_cbm(line);
            },
            calc_item_ttl(line){
                return calc_item_ttl(line);
            },
            calc_carton_ttl(line){
                return calc_carton_ttl(line);
            },
            calc_total_master_ctn(line){
                return calc_total_master_ctn(line);
            },

            async load_order(){
                this.is_loading = true;

                this.order = await this.$hget('order/' + this.invoice_id).then( response => response.data);
                this.order_old = Object.assign(this.order, {});
                console.log(this.order);

                this.is_loading = false;
            },

            async saveOrder() {
                this.is_loading = true;
                let order = await this.$hpost('order/' + this.invoice_id + '/', {order: this.order}).then(r => r.data);

                console.log(order);

                this.$notify({
                    title: 'Success:',
                    message: 'successfully saved PI',
                    group: 'notifications',
                    position: 'top right'
                });

                this.is_loading = false;
            },

            close_chat(){
                this.chat_opened = false;
            },

        }


    }
</script>

<style lang="scss">
    .invoice_items_table {
        table {
            min-width: 2000px;
        }
    }

    $stages-base_color: #b2cee8 #E27E4B #e25662 #4c89be #f08686 #fcdf88 #18e2c4 #bdd6a5 #1dbf77 #e1e1e1 #888888 #5d5d5d #ffffff;
    $stages-highlight_color: #b2cee8 #E27E4B #e25662 #4c89be #f08686 #fcdf88 #18e2c4 #bdd6a5 #1dbf77 #e1e1e1 #888888 #5d5d5d #ffffff;

    @for $i from 1 through 13 {
        .stage_btn-#{$i} {
            border: 2px solid nth($stages-base_color, $i);
        }
    }

    .order-chat-wrapper {
        width:400px;
        height:500px;
        position: fixed;
        bottom:0;
        right:0;
        z-index:900;
        background:white;

        .close {
            width:24px;
            height:24px;
            position: absolute;
            top:5px;
            right:5px;
            z-index:901;
        }
    }



</style>
